import React from 'react';
import TranscriptComponent from './TranscriptComponent';
import InterviewHeaderComponent from './InterviewHeaderComponent';

const score_icon = require("../../img/icons/speedometer-green.png");
const calendar_icon = require("../../img/icons/calendar-icon.png");

interface TechnicalInterviewComponentProps {
    interview?: any;
    agent?:any;
    contractor?:any;
    application?:any;
}

const TechnicalInterviewComponent: React.FC<TechnicalInterviewComponentProps> = ({
	interview = null, agent = null, contractor = null, application = null
}) => {
    return (
    <>
        {interview && (
                <>
                {/* Interview Details Section */}
                {interview.application.id !== application.id && (
                        <div className="bg-gray-100 border-l-4 border-blue-500 text-gray-700 p-4 mb-4" role="info">
                            <p>
                                Note: This interview is associated with a different request for the role of {interview.application.role_name}
                            </p>
                        </div>
                    )}
                <div className="flex flex-row justify-between mb-4 p-4 bg-white rounded-lg">
                    <InterviewHeaderComponent
                        date={interview.date}
                        duration={interview.duration}
                        agent={agent}
                        contractor={contractor}
                        simplifiedDisplay={false}
                    />
                    <div className="flex ">
                        <div className="flex flex-row items-end justify-center items-center big mint-badge">
                            <img
                            style={{
                                width: 16,
                                height: 16,
                                bottom: 10,
                                right: -10,
                            }}
                            src={score_icon}
                            alt="Score icon"
                            className="mr-1"
                            />
                            <span className="text-xs font-semibold ">{"Technical score: " + interview.score}</span>
                        </div>
                    </div>
                </div>

                <div className="bg-purple-light flex flex-col w-full h-full rounded-b-2xl p-3">
                    {/* Interview Summary */}
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Interview Summary</h2>
                        <div className="bg-white rounded-lg p-4">
                            <p className="text-gray-700">{interview.summary}</p>
                        </div>                    
                    </div>

                    {/* Video Section */}
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Video</h2>
                        <div className="w-full" style={{ height: '500px' }}>
                            <iframe
                                title="Interview Video"
                                className="w-full h-full rounded-xl"
                                src={interview.video_link}
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>

                    {/* Transcription Section */}
                    <div className="mt-4 p-4 bg-white rounded-xl shadow space-y-4" style={{marginBottom: '100px'}}>
                        <h2 className="text-lg font-bold mb-3">Transcription</h2>
                        <TranscriptComponent
                            agent={agent}
                            contractor={contractor}
                            transcription={interview.transcription_file}
                            contractorTranscriptionName={interview.contractor_name}
                        />
                    </div>
                    
                    {/* Placeholder for when there is no data */}
                    {!interview && <p>No interview data...</p>}
                </div>
                </>
        )}
        {!interview && <p>No interview data...</p>}
    </>
    );
};

export default TechnicalInterviewComponent;
