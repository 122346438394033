import { DateTime } from "luxon";

export const formatTimestamp = (inputTimestamp: string): string => {
	// Parse the input timestamp string
	const timestamp = new Date(inputTimestamp);

	var formattedDate =
		timestamp.toLocaleString("default", { month: "long" }) +
		" " +
		timestamp.getDate() +
		", " +
		timestamp.getFullYear();

	// Get the date components
	const year = timestamp.getFullYear();
	const month = (timestamp.getMonth() + 1).toString().padStart(2, "0");
	const day = timestamp.getDate().toString().padStart(2, "0");

	// Get the time components
	const hours = timestamp.getHours();
	const minutes = timestamp.getMinutes();

	// Convert hours to AM/PM format
	const amPm = hours >= 12 ? "pm" : "am";
	const formattedHours = hours % 12 || 12; // Handle 0 as 12 for noon/midnight

	// Format the time in HH:mm am/pm format
	const timeString = `${formattedHours}:${minutes
		.toString()
		.padStart(2, "0")} ${amPm}`;

	// Combine the date and time components
	const formattedTimestamp = `${timeString} - ${formattedDate}`;

	return formattedTimestamp;
};

export function formatDateFromString(date: string) {
	var dateObj = new Date(date);

	if (!isNaN(dateObj.getMonth())) {
		var month = dateObj.getMonth() + 1;
		var day = dateObj.getDate();
		var year = dateObj.getFullYear();
		var newdate = month + "/" + day + "/" + year;
		return newdate;
	}
	return date;
}

export function formatStringDateFromServer(date: string | null | undefined) {
	// Early return for null, undefined, or empty string dates
	if (!date) {
		return "--/--/----";
	}

	var dateObj = new Date(date);

	if (isNaN(dateObj.getTime())) { // Check if the date object is valid
		return "--/--/----";
	}

	var dateString =
		dateObj.toLocaleString("default", { month: "long" }) +
		" " +
		dateObj.getDate() +
		", " +
		dateObj.getFullYear();

	return dateString;
}

//function to format a dd-mm-yyy date to something like November 12, 2021
export function formatDateToLongString(date: string) {

	var dateObj = DateTime.fromISO(date, { zone: "utc" });
	return dateObj.toLocaleString(DateTime.DATE_FULL);
	/*
	if (!isNaN(dateObj.getMonth())) {
		var month = dateObj.toLocaleString("default", { month: "long" });
		var day = dateObj.getDate();
		var year = dateObj.getFullYear();
		var newdate = month + " " + day + ", " + year;
		return newdate;
	}
	return date;
	*/
}

export function formatAmountCommas(amount: number) {
	const options = { maximumFractionDigits: 2 };
	const formattedNumber = Intl.NumberFormat("en-US", options).format(
		Math.floor(amount)
	);
	return formattedNumber;
}

/** Gets a UTC time string and returns the proper Local time String. */
export function FormatDateToLocalString(date: string): string {
	date = date.replace(" ", "T");
	if (date.includes("T")) {
		const utcDate = DateTime.fromISO(date, { zone: "utc" });
		const localDate = utcDate.setZone(DateTime.local().zoneName);
		return localDate.toLocaleString(DateTime.DATETIME_MED);
	} else {
		const utcDate = DateTime.fromISO(date, { zone: "utc" });
		return utcDate.toLocaleString(DateTime.DATETIME_MED);
	}
}

export function FormatLink(link: string): string {
	if (link.includes("http://") || link.includes("https://")) {
		return link;
	} else {
		return "https://" + link;
	}
}

export function TruncateText(text: string, length: number = 20): string {
	// Split the text into words
	const words = text.trim().split(/\s+/);

	// If the text has 20 or fewer words, return the original text
	if (words.length <= length) {
		return text;
	}

	// Otherwise, join the first 20 words and append an ellipsis
	return words.slice(0, length).join(" ");
}

//function that returns the business days between two dates
export function BusinessDays(d1: Date, d2: Date): number {
	var days = 0;
	while (d1 < d2) {
		d1.setDate(d1.getDate() + 1);
		if (d1.getDay() !== 0 && d1.getDay() !== 6) {
			days++;
		}
	}
	return days;
}

export function capitalizeWords(fullPhrase: string) {
	return fullPhrase
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ');
};