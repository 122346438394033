import { Outlet, useLoaderData } from "react-router-dom";
import { ContractorTopBar } from "../components/ContractorTopBar";
import { ComunaAPI } from "../managers/ComunaAPI";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ProposalFromServer } from "../types";
import { useEffect } from "react";
import { Footer } from "../components/Footer";

export async function loader({ params }: any) {

	var token = await LocalStorageManager.getToken();
	const proposals = (await ComunaAPI.GetUserProposals(
		token
	)) as ProposalFromServer[];
	var proposal: ProposalFromServer | null;
	proposal = null;
	if (proposals && proposals.length > 0) {
		proposal = proposals[proposals.length - 1];
	}

	const contracts_: any[] = await ComunaAPI.GetUserContracts(token);
	let contracts = [];
	if (contracts_ && contracts_.length > 0) {
		contracts = contracts_;
	}

	return { proposal, contracts };
}

export default function ContractorRoot() {
	const { proposal, contracts }: any = useLoaderData();

	useEffect(() => {
		if (window.location.pathname === "/") {
			if (contracts.length > 0) {
				window.location.href = "/dashboard";
			}
			else {
				window.location.href = "/proposal";
			}
		}
	}, []);

	return (
		<div className="flex flex-col h-full w-full">
			<ContractorTopBar />
			<div className="flex flex-row h h-full">
				<Outlet />
			</div>

			{/** Temporal fix, check propsal layout */}
			{window.location.pathname !== "/proposal" &&
				<Footer />
			}
		</div>
	);
}
