import { Link } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { useLoaderData, Outlet, useNavigate } from "react-router-dom";
import Fuse from "fuse.js";

import { NewRequestForm } from "./NewRequestForm_v1.1";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaAPI } from "../managers/ComunaAPI";
import { UserContext } from "../Providers/UserContext";

import {Calculator} from "./Calculator";

const calculatorIcon = require("../img/icons/calculator.png");
const searchIcon = require("../img/icons/search-icon.png");
const plusIcon = require("../img/icons/plus.png");
const playIcon = require("../img/icons/play.png");
const chevronIcon = require("../img/icons/chevron-dark-up.png");
const xIcon = require("../img/icons/x-purple.png");

//this comes from react router, the function that runs to load the info of this page.
export async function loader({ params }: any) {
	//benchmnark the time for this function to be complete.
	var token = await LocalStorageManager.getToken();
	const resourceRequests = await ComunaAPI.getUserResourceRequests(token);
	const resReqFields = await ComunaAPI.getResReqFields(token);
	const organizationDetails = await ComunaAPI.GetOrganizationProfile(token);

	const noResourceRequests =
		"error" in resourceRequests || resourceRequests.length === 0;

	//we want to make the list of available skills available to components down the three in the view
	var availableSkills: any[] = [];
	resReqFields.skills.map((item: any) => {
		availableSkills.push({
			label: item.name,
			value: item.id,
		});
	});

	if (resourceRequests) {
		return {
			resourceRequests,
			noResourceRequests,
			resReqFields,
			availableSkills,
			organizationDetails
		};
	}
}

const fuseOptions = {
	keys: ["role_name"],
	includeScore: true,
};

export function ClientHiring() {
	const navigate = useNavigate();
	const { user, setBusy }: any = useContext(UserContext);

	const { resourceRequests, noResourceRequests }: any = useLoaderData();
	const [filteredRequests, setFilteredRequests] = useState(resourceRequests);

	const [query, setQuery] = useState("");
	const [activeListMinimized, setActiveListMinimized] = useState(false);
	const [completedListMinimized, setCompletedListMinimized] = useState(false);
	const [showNewForm, setShowNewForm] = useState(false);

	const [showCalculator, setShowCalculator] = useState(false);

	useEffect(() => {
		if (user.role === "accounting") {
			navigate("/team");
		}

		if (
			(window.location.pathname === "/hiring" ||
				window.location.pathname === "/hiring/") &&
			resourceRequests.length > 0
		) {
			
			navigate("/hiring/" + resourceRequests[0].id);

		} else if (window.location.search.includes("new")) {
			setShowNewForm(true);
		}
	}, []);

	useEffect(() => {
		//filter by the query.
		const fuse = new Fuse(resourceRequests, fuseOptions);
		if (query === "") {
			setFilteredRequests(resourceRequests);
		} else {
			const a = fuse.search(query);
			const filtered: any[] = [];
			a.forEach((item: any) => {
				filtered.push(item.item);
			});
			setFilteredRequests(filtered);
		}
	}, [query]);

	useEffect(() => {
		if (showNewForm) {
			//add new query parameter to current location
			window.history.pushState("", "", window.location.pathname + "?new=true");
		}
	}, [showNewForm]);

	function playVideo() {
		var video = document.getElementById("intro-video") as HTMLVideoElement;
		if (video != null) {
			video.play();
			document.getElementById("play-button")!.style.display = "none";
		}
	}

	if (noResourceRequests) {
		return (
			<div
				className="comuna-card w-full m-12 flex flex-row items-center  px-12 py-12"
				style={{ alignSelf: "flex-start" }}>
				<div className="w-full items-start text-left mr-12">
					<h1 className="text-3xl font-bold mb-4">
						Kickstart your hiring with comuna
					</h1>
					<p className="text-xl">
						<ul
							style={{ listStyle: "initial", paddingLeft: 20, color: "black" }}>
							<li className="mt-2">
								Describe the role, required skills, and perks you’d like to
								include.
							</li>
							<li className="mt-2">
								We'll hunt for top candidates - you pick who to interview.
							</li>
							<li className="mt-2">
								Land the ideal candidate? We’ll assist in shaping a stellar
								offer with our local know-how.
							</li>
						</ul>
					</p>
					<Link
						to="/hiring/?new=true"
						className="comuna-primary-button sm icon mt-4"
						onClick={() => {
							setShowNewForm(true);
						}}>
						<img src={plusIcon} alt="plus icon"></img>
						<span>New request</span>
					</Link>
				</div>
				<div className="flex flex-col w-full relative">
					<video
						id="intro-video"
						className="rounded-xl"
						controls
						src="https://thecomuna.com/wp-content/uploads/2024/01/Comuna-V3-Edit_1-18Th-Jan.mp4"></video>
					<button
						id="play-button"
						onClick={playVideo}
						className="comuna-mint-button sm center icon"
						style={{ padding: "16px 32px" }}>
						<span>Check how to hire</span>
						<img src={playIcon} className="ml-2" alt="" />
					</button>
				</div>
				<NewRequestForm
					showForm={showNewForm}
					onClose={() => {
						setShowNewForm(false);
					}}
				/>
			</div>
		);
	}

	function RequestsCount(archived = false) {
		var count = 0;
		if (filteredRequests != null && filteredRequests.length > 0) {
			filteredRequests.forEach((request: any) => {
				if (request.archived === archived) {
					count++;
				}
			});
		}
		return count;
	}

	function RequestButton(props: any) {
		const appCount = props.request.applications.length;
		const isActive = window.location.pathname.includes(props.request.id);

		var classNames =
			"hover-right bg-purple-light text-xl font-medium mb-4 rounded-xl p-4 text-left";
		if (isActive) {
			classNames =
				"hover-right bg-purple-dark text-xl font-bold mb-4 rounded-xl p-4 text-left";
		}

		//IF I dont add that "" to the "to" prop react always renders the first ID only
		//SET BUSY is used all towards the app to indicate something is loading.
		return (
			<Link to={props.request.id+""} className={classNames} onClick={()=>{ setBusy(true); }}>
				{props.request.role_name}
				{appCount > 0 && <span> ({appCount})</span>}
			</Link>
		);
	}

	return (
		<div className="bg-purple-light w-full min-heigh-full flex flex-row">
			<div className="comuna-sidebar flex flex-col pb-6">
				
				<button onClick={()=>{ setShowCalculator(true); }} className="flex flex-row bg-comuna-purple p-4 text-white items-center justify-center calculator-button">
						<img src={calculatorIcon} alt="" />
					 	<b>Try our Calculator</b>
				</button>

				<div className="w-full flex-1 h-64 p-6 pb-12" style={{overflowY:"auto"}}>
					<div
						className="border-2 border-gray-300 flex flex-row p-2 mb-4"
						style={{ borderRadius: "45px", padding: "16px" }}>
						<img
							src={searchIcon}
							style={{ width: 27 }}
							alt="magnifying glass icon"
						/>
						<input
							onChange={(event) => setQuery(event.target.value)}
							className="w-full ml-4 dark-placeholder-text"
							type="text"
							placeholder="Search for requests"
						/>
					</div>

					<div className="my-2 flex flex-row justify-between items-center">
						<span>Active Requests ({RequestsCount()})</span>
						<button
							onClick={(e) => {
								setActiveListMinimized(!activeListMinimized);
							}}
							className="hover-scale">
							<img
								className="transition"
								style={{
									width: "auto",
									height: 10,
									transform: activeListMinimized ? "scale(1,-1)" : "",
								}}
								src={chevronIcon}
								alt="arrow"
							/>
						</button>
					</div>

					{filteredRequests.length > 0 && (
						<div
							className="flex flex-col overflow-hidden transition"
							style={{
								maxHeight: activeListMinimized ? 0 : 100 * RequestsCount(),
							}}>
							{filteredRequests.map((request: any, index: number) => {
								if (request.archived === false) {
									return <RequestButton key={index} request={request} />;
								}
							})}
						</div>
					)}

					<div className="my-2 flex flex-row justify-between items-center">
						<span>Completed Requests ({RequestsCount(true)})</span>
						<button
							onClick={(e) => {
								setCompletedListMinimized(!completedListMinimized);
							}}
							className="hover-scale">
							<img
								className="transition"
								style={{
									width: "auto",
									height: 10,
									transform: completedListMinimized ? "scale(1,-1)" : "",
								}}
								src={chevronIcon}
								alt="arrow"
							/>
						</button>
					</div>

					{filteredRequests.length > 0 && (
						<div
							className="flex flex-col overflow-hidden transition"
							style={{
								maxHeight: completedListMinimized ? 0 : 100 * RequestsCount(true),
							}}>
							{filteredRequests.map((request: any, index: number) => {
								if (request.archived) {
									return <RequestButton key={index} request={request} />;
								}
							})}
						</div>
					)}
				</div>

				<div className="flex flex-col items-center">
					{/*<Link
						to="/hiring/new"
						className="comuna-primary-button sm icon mt-4"
						style={{ width: "84%" }}>
						<img src={plusIcon} alt="plus icon"></img>
						<span>New request</span>
					</Link>*/}
					<Link
						to="/hiring/?new=true"
						className="comuna-primary-button sm icon mt-2"
						onClick={() => {
							setShowNewForm(true);
						}}>
						<img src={plusIcon} alt="plus icon"></img>
						<span>New request</span>
					</Link>
				</div>

			</div>

			<div className="w-full min-heigh-full">
				<Outlet />
			</div>

			<NewRequestForm
				showForm={showNewForm}
				onClose={() => {
					setShowNewForm(false);
				}}
			/>
			{showCalculator &&
				<div className="comuna-popup">
					<div className="relative rounded-xl bg-white p-12 w-full" style={{maxWidth:1424, margin:"auto"}}>
						<button className="comuna-circle-button close-button" onClick={()=>{ setShowCalculator(false); }}>
							<img src={xIcon} alt="" />
						</button>
						<Calculator />
					</div>
				</div>
			}
		</div>
	);
}
 